import React, { useEffect, useState } from "react";
import "./itnews.css";
// import Button from "react-bootstrap/Button";

function ItNews({ type, title, news_title, news_description, image, ...props }) {
  const ref = React.createRef();
  const videoFile = require(`./videos/bg.mp4`).default;
  const [videoEnded, setvideoEnded] = useState(false);

  useEffect(() => {
    if (props.visible && !videoEnded) {
      ref.current.play();
    }
  }, [ref, props, videoEnded]);

  function onVideoEnded() {
    setvideoEnded(true);
  }

  const imageStyle = {
    backgroundImage: `url(${image})`,
  };

  return (
    <>
      <div style={{ opacity: props.visible ? 1 : 0 }}>
        <video hwz="z-index:-1" src={videoFile} ref={ref} onEnded={onVideoEnded} loop muted/>
        <div className={["itNews", type, props.visible ? "fadeIn" : ""].join(" ")}>
          <div className="content">
            <div className="itNewsTitleBackground">
            <h1 className="itSectionTitle">{title}</h1>
            </div>
            <div className="itNewsDescriptionBackground">
                <div className="image" style={imageStyle}></div>
                <div className="itTitleContainer">
                  <h1 className="itTitle">{news_title}</h1>
                </div>
              <div className="description" dangerouslySetInnerHTML={{ __html: (news_description || "").replace(/\n/g, "<br>"),}}>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
}

export default ItNews;
