const API = {
  retrieveRecords(item) {
    return fetch(`${process.env.REACT_APP_API_URL}/items/${item}/?sort=sort&_random=` + Math.random())
      .then(r => r.json());
  },
  getImageUrl(id) {
    return id ? `${process.env.REACT_APP_API_URL}/assets/${id}` : '';
  }
}

export default API