import { useEffect, useState } from 'react';
import Intro from './common/Intro';
import PageContent from './common/PageContent';
import API from './Api';
import { preloadImage, useLocalStorage } from './utils';

function Page(props) {
	const [introRunning, setIntroRunning] = useState(!props.noIntro);
	const [items, setItems] = useLocalStorage(`${props.type}_items`, []);
	const [storedCurrentItemIndex, storeCurrentItemIndex] = useLocalStorage(`${props.type}_index`, 0);
	const [currentItemIndex] = useState(storedCurrentItemIndex + 1);
	const [currentItem, setCurrentItem] = useState({});

	// refresh and cache items
	useEffect(() => {
		async function run() {
			try {
				const items = await API.retrieveRecords(props.type);
				setItems(items.data);
			} catch (e) {
				console.error(e);
			}
		}

		run();

		const interval = setInterval(run, 5000);

		return () => clearInterval(interval);
	}, [props]);

	// set current item
	useEffect(() => {
		let i = currentItemIndex;

		if (!items[i]) {
			if (items.length && items[i % items.length]) {
				i = i % items.length;
			} if (items[0]) {
				i = 0;
			}
		}

		items.length && setCurrentItem(items[i]);

		storeCurrentItemIndex(i);
		console.log(i)
	}, [currentItemIndex, items]);

	// preload image for a further usage
	useEffect(() => {
		if (currentItem.image) {
			preloadImage(API.getImageUrl(currentItem.image));
		}
	}, [currentItem]);

	useEffect(() => {
		if (props.layoutWidth) {
			document.documentElement.style.setProperty('--layout-w', `${props.layoutWidth}px`);
		}

		if (props.layoutHeight) {
			document.documentElement.style.setProperty('--layout-h', `${props.layoutHeight}px`);
		}
	}, [props]);

	return (
		<>
			{!props.noIntro && introRunning &&
				<Intro
					type={props.type}
					onEnd={() => setIntroRunning(false)}
				/>
			}
			<PageContent
				visible={!introRunning}
				type={props.type}
				title={currentItem.title}
				description={currentItem.description}
				image={API.getImageUrl(currentItem.image)}
			/>

	

		</>
	)
}

export default Page;