import React, { useEffect, useState } from 'react';

function PageContent({
  type,
  title,
  description,
  image,
  ...props
}) {
  const ref = React.createRef();
  const videoFile = require(`../videos/${type}/bg.mp4`).default;
  const [videoEnded, setvideoEnded] = useState(false);

  useEffect(() => {
    if (props.visible && !videoEnded) {
      ref.current.play();
    }
  }, [ref, props, videoEnded]);

  function onVideoEnded() {
    setvideoEnded(true);
  }

  const imageStyle = {
    backgroundImage: `url(${image})`,
  }

  return (
    <>
      <div style={{ opacity: props.visible ? 1 : 0 }}>
        <video hwz="z-index:-1" src={videoFile} ref={ref} onEnded={onVideoEnded} muted />
        <div className={['pageContent', type, props.visible ? 'fadeIn' : ''].join(' ')}>
          <div className="content">
            <h1 className="title">
              {title}
            </h1>
            <div className="description itNewsBackground" dangerouslySetInnerHTML={ { __html: (description || '').replace(/\n/g, '<br>') } }></div>
          </div>
          <div className="image" style={imageStyle}></div>
        </div>
      </div>
    </>
  )
}

export default PageContent;