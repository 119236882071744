import {
  BrowserRouter as Router,
  Switch,
  Route,
  Redirect
} from "react-router-dom";
import IT from "./common/IT/IT";
import SecurityPosture from "./common/IT_SecurityPosture/SecurityPosture";
import Malware from "./common/IT_Malware/Malware";
import Firewalls from "./common/IT_Firewalls/Firewalls";
import ExecutiveView from "./common/IT_ExecutiveView/ExecutiveView";
import AccessAnomalies from "./common/IT_AccessAnomalies/AccessAnomalies";

import Page from './Page';

function App() {
  return (
    <Router>
      <Switch>
        <Route exact path="/">
          <Redirect to="/news" noIntro="1"/>
        </Route>
        <Route path="/news">
          <Page type="news" noIntro="1"/>
        </Route>
        <Route path="/team">
          <Page type="team" noIntro="1"/>
        </Route>
        <Route path="/lobby">
          <Page type="lobby" noIntro="1" layoutWidth="1920" layoutHeight="1080" />
        </Route>
        <Route path="/it-news">
          <IT type="it_news" noIntro="1" layoutWidth="2948" layoutHeight="350" />
        </Route>
        <Route path="/security-posture">
          <SecurityPosture type="it_security_posture" noIntro="1" layoutWidth="2948" layoutHeight="350" />
        </Route>
        <Route path="/malware">
          <Malware type="it_malware" noIntro="1" layoutWidth="2948" layoutHeight="350" />
        </Route>
        <Route path="/firewalls">
          <Firewalls type="it_firewalls" noIntro="1" layoutWidth="2948" layoutHeight="350" />
        </Route>
        <Route path="/executive-view">
          <ExecutiveView type="it_executive_view" noIntro="1" layoutWidth="2948" layoutHeight="350" />
        </Route>
        <Route path="/access-anomalies">
          <AccessAnomalies type="it_access_anomalies" noIntro="1"  layoutWidth="2948" layoutHeight="350" />
        </Route>
      </Switch>
    </Router>
  );
}

export default App;
