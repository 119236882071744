import React, { useEffect } from 'react';

function Intro({
  type,
  onEnd,
  ...props
}) {
  const ref = React.createRef();
  const videoFile = require(`../videos/${type}/intro.mp4`).default;

  useEffect(() => {
    ref.current.addEventListener('ended', () => {
      onEnd && onEnd();
    });
  }, [ref, onEnd]);

  return (
    <video hwz="z-index:-1" src={videoFile} ref={ref} autoPlay muted />
  )
}

export default Intro;