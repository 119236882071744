import React from "react";
import "./malware.css";
// import Button from "react-bootstrap/Button";

function MalwareMarkup({
  type,
  title,
  mw_timeframe,
  mw_ticker_one_title,
  mw_ticker_one_description,
  mw_ticker_two_title,
  mw_ticker_two_description,
  mw_ticker_three_title,
  mw_ticker_three_description,
  // image,
  ...props
}) {
  const ref = React.createRef();
  const videoFile = require(`./videos/bg-video.mp4`).default;
  // const [videoEnded, setvideoEnded] = useState(false);

  // useEffect(() => {
  //   if (props.visible && !videoEnded) {
  //     ref.current.play();
  //   }
  // }, [ref, props, videoEnded]);

  // function onVideoEnded() {
  //   setvideoEnded(true);
  // }

  // const imageStyle = {
  //   backgroundImage: `url(${image})`,
  // };

  return (
    <>
      <div style={{ opacity: props.visible ? 1 : 0 }}>
        <video
          // className="spVideo"
          hwz="z-index:-1"
          src={videoFile}
          ref={ref}
          // onEnded={onVideoEnded}
          loop
          muted
          autoPlay
        />
        <div
          className={[
            "malwareMarkup",
            type,
            props.visible ? "fadeIn" : "",
          ].join(" ")}
        >
          <div className="content">
            <div className="sectionTitleContainer">
              <h1 className="sectionTitle">{title}</h1>
              <h1 className="mwTimeframe">{mw_timeframe}</h1>
            </div>

            <div className="mwTickerContainer">
              <div className="mwTicker xlTicker">
                <div className="mwTickerTitle">{mw_ticker_one_title}</div>
                <div id="redBG" className="mwTickerStatistic xlTickerStatistic">{mw_ticker_one_description}</div>
              </div>
              <div className="mwTicker xlTicker">
                <div className="mwTickerTitle">{mw_ticker_two_title}</div>
                <div id="orangeBG" className="mwTickerStatistic xlTickerStatistic">{mw_ticker_two_description}</div>
              </div>
              <div className="mwTicker xlTicker">
                <div className="mwTickerTitle">{mw_ticker_three_title}</div>
                <div className="mwTickerStatistic xlTickerStatistic">{mw_ticker_three_description}</div>
              </div>
            </div>

            {/* <div className="securityPostureMarkupDescriptionBackground">
                <div className="image" style={imageStyle}></div>
                <div className="titleContainer">
                  <h1 className="title">{title}</h1>
                </div>
              <div className="description" dangerouslySetInnerHTML={{ __html: (description || "").replace(/\n/g, "<br>"),}}>
              </div>
            </div> */}
          </div>
        </div>
      </div>
    </>
  );
}

export default MalwareMarkup;
